import React, { useEffect, useState } from "react";

import "react-chat-elements/dist/main.css";
import { ChatList } from "react-chat-elements";

import { getExploreList } from "../api/getMainnetInfuraApi";

export const AllPdf = ({ chatRoomCallback }) => {
  const [exploreList, setExploreList] = useState([]);

  const chatChannel = [
    {
      botId: "877a7d26-76b4-4a41-883e-1decc084a305",
      walletAddress: "0xf361e14DFe6242D20FC2B23A5b36eb2CCD753349",
      avatar: "/esg.png",
      title: "AI客服",
      subtitle: "全部報告",
      description: "",
    },
  ];

  useEffect(() => {
    getExploreList().then((result) => {
      if (result.code == 0) {
        const bots = result.data.map(channel => channel.botId);

        chatChannel[0].bots = result.data; //bots;
        setExploreList(chatChannel);
        chatRoomCallback(chatChannel[0]); // auto open the first channel
      }
    });

  }, []);

  return (
    <ChatList
      className="chat-list"
      dataSource={exploreList}
      onClick={(bot) => {
        chatRoomCallback(bot);
      }}
    />
  );
};
