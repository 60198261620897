import { useState, useEffect } from "react";
import {
  AppBar,
  Box,
  Button,
  Chip,
  Toolbar,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

export const KeywordContainer = ({ setSearchKeywordDialogOpen, setChatDialogOpen }) => {
  const storedItem = localStorage.getItem("keywords");
  const storedKeywords = storedItem ? JSON.parse(storedItem) : ["組織詳細資訊",
  "員工人數",
  "政策承諾",
  "能源使用",
  "消費者隱私",];
  const [keywords, setKeywords] = useState(storedKeywords);

  // 從 localStorage 中取值
  useEffect(() => {
    const storedKeywords = localStorage.getItem("keywords");
    if (storedKeywords) {
      setKeywords(JSON.parse(storedKeywords));
    }
  }, []);

  // 將值保存到 localStorage 中
  useEffect(() => {
    localStorage.setItem("keywords", JSON.stringify(keywords));
  }, [keywords]);

  const handleAddKeyword = () => {
    const newKeyword = prompt("請輸入新的關鍵字");
    if (newKeyword) {
      setKeywords([...keywords, newKeyword]);
    }
  };

  return (
    <Box
      style={{
        flexDirection: "column",
        display: "flex",
      }}
    >
      <AppBar
        sx={{
          position: "fixed",
          height: 40,
        }}
      >
        <Toolbar
          sx={{
            minHeight: "5px !important",
            display: "flex",
          }}
        >
          <IconButton
            edge="start"
            color="inherit"
            onClick={() => {
              setSearchKeywordDialogOpen(false);
              setChatDialogOpen(false);
            }}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
            搜尋詞管理
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        sx={{
          height: 40,
        }}
      />
      <Box className="w-80 flex flex-col self-center">
        {keywords.map((keyword, index) => (
          <TextField
            className="bg-[#FFF] mt-2"
            key={index}
            value={keyword}
            size="small"
            onChange={(e) => {
              const newKeywords = [...keywords];
              newKeywords[index] = e.target.value;
              setKeywords(newKeywords);
            }}
          />
        ))}
        { keywords.length < 5 && <Button onClick={handleAddKeyword}>新增關鍵字</Button> }
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "10px",
          marginTop: "20px",
        }}
      >
        {keywords.map((keyWord, index) => (
          <Chip
            className="bg-[#FFF] mt-2"
            key={index}
            label={keyWord}
            variant="outlined"
          />
        ))}
      </Box>
    </Box>
  );
};
