import React, { useEffect, useState } from "react";

import "react-chat-elements/dist/main.css";
import { ChatList } from "react-chat-elements";

import { getExploreList } from "../api/getMainnetInfuraApi";

export const ExploreListContainer = ({ chatRoomCallback }) => {
  const [exploreList, setExploreList] = useState([]);

  useEffect(() => {
    getExploreList().then((result) => {
      if (result.code === 0) {
        setExploreList(result.data);
      }
    });

    const intervalId = setInterval(() => {
      getExploreList().then((result) => {
        if (result.code === 0) {
          setExploreList(result.data);
        }
      });
    }, 10 * 1000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <ChatList
      className="chat-list"
      dataSource={exploreList}
      onClick={(bot) => {
        console.log("bot " + JSON.stringify(bot));
        chatRoomCallback(bot);
      }}
    />
  );
};
