import React, { useState } from "react";

import { styled } from "@mui/material/styles";
import {
  Box,
  BottomNavigation,
  BottomNavigationAction,
  Dialog,
} from "@mui/material";

import { KeywordContainer } from "./Component/EditKeyword";
// import { ChatListContainer} from './ChatListComponent'
import { ExploreListContainer } from "./Component/ExploreList";
import { AllPdf } from "./Component/AllPdf";
import {
  CreateContainer,
  AvatarContainer,
  UploadContainer,
} from "./BotComponent";
// import { ProfileContainer} from './ProfileComponent'
import { MessageContainer } from "./MessageComponent";
import { Message } from "./Component/Message";

var height = document.documentElement.clientHeight;
// var width  = window.screen.width

const BottomNavigationActionButton = styled(BottomNavigationAction)({
  color: "#757575",
  "&.Mui-selected": {
    color: "#FFB41D",
  },
});

function App() {
  const searchParams = new URLSearchParams(window.location.search);
  const role = searchParams.get("role") || ""; // ?role=lecturer
  const initPage = role === "lecturer" ? 2 : 1;

  const [page, setPage] = useState(initPage);

  const [walletAddress, setWalletAddress] = React.useState(
    "0xf322161cbd34cbbc060765edb95c7b8ccbba314f"
  );

  const [bot, setBot] = useState(null);

  const [chatDialogOpen, setChatDialogOpen] = useState(false);
  const [messageDialogOpen, setMessageDialogOpen] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [uploadDialogOpen, setUploadDialogOpen] = useState(false);
  // const [profileDialogOpen, setProfileDialogOpen] = useState(false);
  const [avatarDialogOpen, setAvatarDialogOpen] = useState(false);
  const [searchKeywordDialogOpen, setSearchKeywordDialogOpen] = useState(false);

  // const requestAppGeneratePky = (callback) => {
  //   window.parent.postMessage({method:"generatePky", data:""}, '*');

  //   const eventCallback = (event)=>{
  //     if (event.data.method) {
  //       console.log("test99 onReceiveMessage "+JSON.stringify(event.data))

  //       switch (event.data.method) {
  //         case "returnAuthAddress":
  //           callback(event.data.data)
  //           window.removeEventListener('message', eventCallback, false);

  //           break
  //       }
  //     }
  //   }

  //   window.addEventListener('message', eventCallback, false);
  //   return
  // }

  // const isValidAddress = (address) => {
  //   var pattern = new RegExp('^0x[a-fA-F0-9]{40}$')
  //   return String(address).match(pattern)
  // };

  // useEffect(
  //   () => {

  //     window.addEventListener('message', (event)=>{
  //       if (event.data.method) {
  //         console.log("test99 onReceiveMessage "+JSON.stringify(event.data))

  //         switch (event.data.method) {
  //           case "returnWallet":
  //             if (!isValidAddress(event.data.data)) {
  //               requestAppGeneratePky((response)=>{
  //                 window.parent.postMessage({method:"setPoaAddress", data:response}, '*');
  //                 setWalletAddress(response)
  //               })
  //             } else {
  //               setWalletAddress(event.data.data)
  //             }
  //             break
  //           case "returnLocation":
  //             var url = event.data.data
  //             console.log('url '+url)

  //             const { href, protocol, hostname, pathname, search, searchParams } = new URL(url)
  //             if(searchParams.has("bot")) {
  //               var botId = searchParams.get("bot")
  //               console.log('botId '+botId)

  //               getExploreList().then(result=>{
  //                 if(result.code==0) {
  //                   var botSearch = result.data.find(item=>item.botId==botId)
  //                   if(botSearch) {
  //                     setBot(botSearch)
  //                     setMessageDialogOpen(true)
  //                   }
  //                 }
  //               })
  //             }

  //             break
  //         }
  //       }
  //     }, false);
  //     window.parent.postMessage({method:"getWallet", data:""}, '*');
  //     window.parent.postMessage({method:"getLocation", data:""}, '*');

  //     const pathname = window.location.pathname
  //     if (pathname && pathname.length>1) {
  //       console.log(pathname.substr(1))
  //     }

  //   },[]
  // )

  const dialogStyles = {
    "& .MuiPaper-root": {
      background: "linear-gradient(#000000,#052F41)",
      backgroundRepeat: "no-repeat",
      backgroundSize: "100% 100%",
    },
    "& .MuiBackdrop-root": {
      backgroundColor: "transparent",
    },
  };

  return (
    <React.Fragment>
      <Box
        sx={{
          width: "100%",
          height: { height },
          backgroundColor: "#ffffff",
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
          flexDirection: "column",
          display: "flex",
        }}
      >
        {/* <Box style={{
          visibility: page==0?"visible":"hidden"
        }}
        className="fixed w-full h-[calc(100%-44px)] bg-gradient-to-b from-[#000] to-[#052F41] bg-no-repeat bg-100% overflow-auto"
        >
          {
            page==0?
            <ChatListContainer {...{walletAddress:walletAddress, chatRoomCallback:(bot)=>{
              if(bot) {
                setBot(bot)
                setMessageDialogOpen(true)
              }
            }}}/>
            :
            <Box/>
          }

        </Box> */}

        <Box
          style={{
            visibility: page == 1 ? "visible" : "hidden",
          }}
          className="fixed w-full h-[calc(100%-44px)] bg-gradient-to-b from-[#000] to-[#052F41] bg-no-repeat bg-100% overflow-auto"
        >
          {page == 1 ? (
            <AllPdf
              {...{
                chatRoomCallback: (bot) => {
                  if (bot) {
                    setBot(bot);
                    setChatDialogOpen(true);
                  }
                },
              }}
            />
          ) : (
            <Box />
          )}
        </Box>

        <Box
          style={{
            position: "fixed",
            width: "100%",
            height: height - 42,
            background: "linear-gradient(#000000,#052F41)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
            overflow: "auto",
            visibility: page == 2 ? "visible" : "hidden",
          }}
        >
          {page == 2 ? (
            <ExploreListContainer
              {...{
                chatRoomCallback: (bot) => {
                  if (bot) {
                    setBot(bot);
                    setMessageDialogOpen(true);
                  }
                },
              }}
            />
          ) : (
            <Box />
          )}
        </Box>

        {role === "lecturer" && (
          <Box
            style={{
              position: "fixed",
              width: "100%",
              top: "auto",
              bottom: 0,
              backgroundColor: "#ffffff",
            }}
          >
            <BottomNavigation
              value={page}
              onChange={(event, newValue) => {
                if (newValue == 0) {
                  setCreateDialogOpen(true);
                } else {
                  setPage(newValue);
                }
                // else if(newValue==4){
                //   setProfileDialogOpen(true)
                // }
              }}
              showLabels
              className="bg-[#000000F0] h-11"
            >
              {/* <BottomNavigationActionButton label="Chats"  showLabel={true}/> */}
              <BottomNavigationActionButton label="檔案上傳" showLabel={true} />
              <BottomNavigationActionButton label="AI客服" showLabel={true} />
              <BottomNavigationActionButton label="檔案列表" showLabel={true} />
              {/* <BottomNavigationActionButton label="Profile" showLabel={true}/> */}
            </BottomNavigation>
          </Box>
        )}
      </Box>

      <Dialog
        fullScreen
        open={searchKeywordDialogOpen}
        onClose={() => {
          setSearchKeywordDialogOpen(false);
        }}
        sx={dialogStyles}
      >
        <KeywordContainer {...{ setSearchKeywordDialogOpen, setChatDialogOpen }} />
      </Dialog>

      <Dialog
        fullScreen
        open={chatDialogOpen}
        onClose={() => {
          setChatDialogOpen(false);
        }}
        sx={dialogStyles}
      >
        <Message
          {...{
            walletAddress: walletAddress,
            bot: bot,
            setChatDialogOpen,
            uploadCallback: (bot) => {
              if (bot) {
                setBot(bot);
                setUploadDialogOpen(true);
              }
            },
            avatarCallback: (bot) => {
              if (bot) {
                setBot(bot);
                setAvatarDialogOpen(true);
              }
            },
            keyCallback: (bot) => {
              setSearchKeywordDialogOpen(true);
            },
          }}
        />
      </Dialog>

      <Dialog
        fullScreen
        open={messageDialogOpen}
        onClose={() => {
          setMessageDialogOpen(false);
        }}
        sx={dialogStyles}
      >
        <MessageContainer
          {...{
            walletAddress: walletAddress,
            bot: bot,
            setMessageDialogOpen,
            uploadCallback: (bot) => {
              if (bot) {
                setBot(bot);
                setUploadDialogOpen(true);
              }
            },
            avatarCallback: (bot) => {
              if (bot) {
                setBot(bot);
                setAvatarDialogOpen(true);
              }
            },
          }}
        />
      </Dialog>

      <Dialog
        fullScreen
        open={createDialogOpen}
        onClose={() => {
          setCreateDialogOpen(false);
        }}
        // TransitionComponent={Transition}
        sx={{
          "& .MuiPaper-root": {
            background: "linear-gradient(#000000,#052F41)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent", // Try to remove this to see the result
          },
        }}
      >
        <CreateContainer
          {...{
            walletAddress,
            setCreateDialogOpen,
            createCallback: (bot) => {
              if (bot) {
                setBot(bot);
                setCreateDialogOpen(false);
                setUploadDialogOpen(true);
              }
            },
          }}
        />
      </Dialog>

      <Dialog
        fullScreen
        open={uploadDialogOpen}
        onClose={() => {
          setUploadDialogOpen(false);
        }}
        // TransitionComponent={Transition}
        sx={{
          "& .MuiPaper-root": {
            background: "linear-gradient(#000000,#052F41)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent", // Try to remove this to see the result
          },
        }}
      >
        <UploadContainer
          {...{
            walletAddress,
            bot: bot,
            setUploadDialogOpen,
            chatRoomCallback: (bot) => {
              if (bot) {
                setUploadDialogOpen(false);
                setBot(bot);
                setMessageDialogOpen(true);
              }
            },
          }}
        />
      </Dialog>

      <Dialog
        fullScreen
        open={avatarDialogOpen}
        onClose={() => {
          setUploadDialogOpen(false);
        }}
        // TransitionComponent={Transition}
        sx={{
          "& .MuiPaper-root": {
            background: "linear-gradient(#000000,#052F41)",
            backgroundRepeat: "no-repeat",
            backgroundSize: "100% 100%",
          },
          "& .MuiBackdrop-root": {
            backgroundColor: "transparent", // Try to remove this to see the result
          },
        }}
      >
        <AvatarContainer
          {...{
            walletAddress,
            bot: bot,
            setAvatarDialogOpen,
            avatarCallback: (bot) => {
              if (bot) {
                setAvatarDialogOpen(false);
                setBot(bot);
                setMessageDialogOpen(true);
              }
            },
            deleteBotCallback: () => {
              setAvatarDialogOpen(false);
              setPage(2);
              setMessageDialogOpen(false);
            },
          }}
        />
      </Dialog>

      {/* <Dialog
        fullScreen
        open={profileDialogOpen}
        onClose={() => {
          setUploadDialogOpen(false)
        }}
        sx={{
          '& .MuiPaper-root': {
            background:"linear-gradient(#000000,#052F41)",
            backgroundRepeat:"no-repeat",
            backgroundSize:"100% 100%",
          },
          '& .MuiBackdrop-root': {
            backgroundColor: 'transparent' // Try to remove this to see the result
          }
        }}
      >
        <ProfileContainer {...{walletAddress, setProfileDialogOpen, newAccountCallback:()=>{
          requestAppGeneratePky((response)=>{
            setWalletAddress(response)
          })
        }, chatRoomCallback:(bot)=>{
          if(bot) {
            setBot(bot)
            setMessageDialogOpen(true)
          }
        }}}/>
      </Dialog> */}
    </React.Fragment>
  );
}

export default App;
