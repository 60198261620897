
const apiUrl = "https://xpert-api.azurefd.net"


export const sendMessage = (params) => {
    return fetch(
        `${apiUrl}/api/sendMessageForESG`,{
        method: 'POST',
        body: JSON.stringify(params),
        headers: new Headers({
          'Content-Type': 'application/json'
        })
      }
    )
    .then((response) => response.body)
    .then((body) => {
        return body
    });
};

export const getMessageList = (params) => {
    return fetch(
        `${apiUrl}/api/getMessageList`,{
        method: 'POST',
        body: JSON.stringify(params),
        headers: new Headers({
          'Content-Type': 'application/json'
        })
      }
    )
    .then((response) => response.json())
    .then((data) => {
        return data
    });
};

export const getChatList = (params) => {
  return fetch(
      `${apiUrl}/api/getChatList`,{
      method: 'POST',
      body: JSON.stringify(params),
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    }
  )
  .then((response) => response.json())
  .then((data) => {
      return data
  });
};

export const getExploreList = (params) => {
  return fetch(
      `${apiUrl}/api/getExploreList`,{
      method: 'POST',
      body: JSON.stringify(params),
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    }
  )
  .then((response) => response.json())
  .then((data) => {
      return data
  });
};

export const getBotList = (params) => {
  return fetch(
      `${apiUrl}/api/getBotList`,{
      method: 'POST',
      body: JSON.stringify(params),
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    }
  )
  .then((response) => response.json())
  .then((data) => {
      return data
  });
};

export const postCreateBot = (params) => {
  return fetch(
      `${apiUrl}/api/createBot`,{
      method: 'POST',
      body: JSON.stringify(params),
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    }
  )
  .then((response) => response.json())
  .then((data) => data);
};

export const postUpdateBot = (params) => {
  return fetch(
      `${apiUrl}/api/updateBot`,{
      method: 'POST',
      body: JSON.stringify(params),
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    }
  )
  .then((response) => response.json())
  .then((data) => {
      return data
  });
};

export const postUpdateKnowledgeBase = (params) => {
  return fetch(
      `${apiUrl}/api/updateKnowledgeBase`,{
      method: 'POST',
      body: JSON.stringify(params),
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    }
  )
  .then((response) => response.json())
  .then((data) => {
      return data
  });
};

export const postCreateAvatar = (params) => {
  return fetch(
      `${apiUrl}/api/createAvatar`,{
      method: 'POST',
      body: JSON.stringify(params),
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    }
  )
  .then((response) => response.json())
  .then((data) => {
      return data
  });
};

export const postUploadAvatar = (params) => {
  return fetch(
      `${apiUrl}/api/uploadAvatar`,{
      method: 'POST',
      body: params,
      // headers: new Headers({
      //   'Content-Type': 'application/json'
      // })
    }
  )
  .then((response) => response.json())
  .then((data) => {
      return data
  });
};

export const getKnowledgeBase = (params) => {
  return fetch(
      `${apiUrl}/api/getKnowledgeBase`,{
      method: 'POST',
      body: JSON.stringify(params),
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    }
  )
  .then((response) => response.json())
  .then((data) => {
      return data
  });
};

export const deleteKnowledgeBase = (params) => {
  return fetch(
      `${apiUrl}/api/deleteKnowledgeBase`,{
      method: 'POST',
      body: JSON.stringify(params),
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    }
  )
  .then((response) => response.json())
  .then((data) => {
      return data
  });
};

export const deleteBot = (params) => {
  return fetch(
      `${apiUrl}/api/deleteBot`,{
      method: 'POST',
      body: JSON.stringify(params),
      headers: new Headers({
        'Content-Type': 'application/json'
      })
    }
  )
  .then((response) => response.json())
  .then((data) => {
      return data
  });
};