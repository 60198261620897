import React, { useEffect, useState, useRef } from 'react'
import { Box, Button, IconButton, Menu, MenuItem, Typography } from '@mui/material';

import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';

import CloseIcon from '@mui/icons-material/Close';
import SendIcon from '@mui/icons-material/Send';
import MenuIcon from '@mui/icons-material/Menu';

import "react-chat-elements/dist/main.css"
import { MessageList, Input } from "react-chat-elements"

import { getMessageList, sendMessage } from './api/getMainnetInfuraApi'
import MarkdownPreview from "@uiw/react-markdown-preview";


export const MessageContainer = ({walletAddress, bot, setMessageDialogOpen, uploadCallback, avatarCallback})=>{

    const ref = useRef(null);
    const inputRef = useRef(null);

    const [anchorEl, setAnchorEl] = useState(null);

    const [messageList, setMessageList] = useState([]);

    useEffect(
        () => {

            getMessageList({
                walletAddress:walletAddress,
                botId:bot.botId
            }).then(result=>{

                if(result.code===0) {
                    setMessageList(result.data)
                }
            })
            
        },[]
    )

    useEffect(
        () => {
            if(messageList.length!=0) {
                scrollToBottom()
            }
        },[messageList]
    )
    
    const scrollToBottom = () => {
        ref.current?.scrollIntoView({
            behavior:'auto',
            block:'end'
        })
    }; 
    

    const formattedMessages = messageList.map((msg) => ({
        ...msg,
        text: <MarkdownPreview source={msg.text} />,
      }));

    return  <Box style={{
    }}>
        <AppBar sx={{ 
            position: 'fixed',
            height:40,
        }}>
            <Toolbar sx={{ 
                minHeight: "5px !important",
                display: "flex",
            }}>
                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={()=>{
                        setMessageDialogOpen(false)
                    }}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>

                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    {bot.title}
                </Typography>

                <IconButton
                    size="large"
                    color="inherit"
                    aria-label="menu"
                    onClick={(event)=>{
                        setAnchorEl(event.currentTarget);
                    }}
                >
                    <MenuIcon />
                </IconButton>
                <Menu
                    id="menu-appbar"
                    anchorEl={anchorEl}
                    anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                    keepMounted
                    transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                    }}
                    open={Boolean(anchorEl)}
                    onClose={()=>{
                        setAnchorEl(null);
                    }}
                >
                    {
                        // bot.walletAddress==walletAddress?
                        <MenuItem onClick={()=>{
                            uploadCallback(bot)
                        }}>文檔</MenuItem>
                        // :
                        // <div/>
                    }
                    {
                        // bot.walletAddress==walletAddress?
                        <MenuItem onClick={()=>{
                            avatarCallback(bot)
                        }}>管理</MenuItem>
                        // :
                        // <div/>
                    }

                </Menu>
            </Toolbar>
        </AppBar>
        <Box sx={{
            height:40,
        }}/>
        <MessageList
            className='message-list'
            lockable={false}
            toBottomHeight={'100%'}
            dataSource={formattedMessages}
        />
        <Box sx={{
            height:48,
        }}/>
        <div ref={ref}/>
        <Box style={{
          position: 'fixed',
          width:'100%',
          top: 'auto',
          bottom: 0,
          backgroundColor:"#ffffff",
        }}>
            <Input
                referance={inputRef}
                placeholder="Type here..."
                multiline={true}
                rightButtons={<Button
                onClick={()=>{

                    if(inputRef.current.value==="") {
                        return
                    }

                    var inputMsg = inputRef.current.value

                    inputRef.current.value = "";

                    setMessageList(prev=>[...prev, {
                        position:"right",
                        type:"text",
                        text:inputMsg,
                    }]);

                    sendMessage({
                        walletAddress:walletAddress,
                        botId:bot.botId,
                        message:inputMsg
                    }).then(stream=>{
                        const reader = stream.getReader();

                        setMessageList(prev=>[...prev, {
                            position:"left",
                            type:"text",
                            text:"",
                        }]);
                
                        (async () => {
                            var response = ""
                            while(true) {
                                const {done, value} = await reader.read();
                                if (done) {
                                    break;
                                }
                                var text = new TextDecoder("utf-8").decode(value);
                                response += text
                                setMessageList(prev=>[...prev.slice(0, prev.length-1),         {
                                    ...prev[prev.length],
                                    position:"left",
                                    type:"text",
                                    text: response,
                                },]);
                            }
                        })()


                    })
                }}>
                    <SendIcon />
                </Button>}
            />
        </Box>
    </Box>
}